import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';
import ConfirmModal from '../../components/ConfirmModal';
import appConst from '../../config/const';

class ChangeVerifyRequire extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            domain: '',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            message: "",
            data: {},
            actionObj: {

                domain: "",

                action: "",
            },
            selectedAction: appConst.CHANGE_VERIFY_REQUIRED.needRequired,

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {

            if (nextProps.action == types.CHANGE_VERIFY_REQUIRED) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                window.LadiUI.closeModalById('confirm-required-verify');
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                    // this.setState({
                    //     message: nextProps.result,
                    //     data: nextProps.data.data ? nextProps.data.data : false
                    // })
                    setTimeout(() => {
                        window.LadiUI.closeModalById('modal-required-verify');
                        // const { actionObj } = this.state;
                        // const jsonData = {
                        //     "domain": actionObj.domain,
                        // }
                        // this.props.checkDomain(jsonData);
                    }, 500);
                    // setTimeout(function () {
                    //     window.LadiUI.closeModal(_seft.ref.current);
                    // },1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }
    submit = (selectedID) => {

        this.selectedID = selectedID;

        window.LadiUI.showModalById('confirm-required-verify');

    }

    onChangeInput = (event) => {

        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option,
        })
    }


    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })

    }
    changeVerify = () => {
        const { actionObj, selectedAction } = this.state;
        const jsonData = {
            is_verify: selectedAction.value
        }
        this.props.changeVerifyRequired(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }


    render() {
        const { actionObj, message, data, selectedAction } = this.state;

        const title = 'Thay đổi yêu cầu xác thực tài khoản';
        const isLoadingDelete = this.props.loading && (this.props.waitting == types.DELETE_DOMAIN);
        return (
            <div ref={this.ref} id="modal-required-verify" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-required-verify">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-required-verify"><i className="ladi-icon icon-e-remove" data-dismiss="modal-required-verify" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">

                        <div className="ladiui-tab-content" style={{ height: "160px" }}>

                            <div className="ladiui-row">

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Hành động</label>
                                    <div className="ladiui-select-options">

                                        <span className="ladiui-select-init">{
                                            selectedAction.name
                                        }</span>
                                        <input type="hidden" name="select" defaultValue />
                                        <ul>
                                            {
                                                _.map(appConst.CHANGE_VERIFY_REQUIRED, option =>
                                                    <li key={option.value} className="select-item" onClick={() => this.onChangeAction(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>


                        {
                            message &&
                            <label className="ladiui-label">{message}</label>
                        }
                        {
                            data && data.length > 0 &&

                            _.map(data, (item, index) =>
                                <label className="ladiui-label" key={index}>
                                    {"URL = " + item.url + " - Trạng thái: " + item.status}
                                </label>
                            )

                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-required-verify">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-required-verify" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
                <ConfirmModal
                    id="confirm-required-verify"
                    title="Thay đổi cách xác thực tài khoản"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.changeVerify()}
                    isLoading={isLoadingDelete}
                />

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeVerifyRequired: (data) => dispatch(toolActions.changeVerifyRequired(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVerifyRequire);