import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';

import * as types from '../../redux/futures/tools/types';
import ConfirmModal from '../../components/ConfirmModal';
import appConst from '../../config/const';

class BlockDomain extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            domain: '',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            message: "",
            result_check: "",
            data: {},
            note: "",
            actionObj: {

                ip: "",

                action: "",
            },
            selectedAction: appConst.ACTION_IP_BLOCK.addBlackList,

        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {


            if (nextProps.action == types.BLOCK_IP_FORM) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    window.LadiUI.toastr('success', '', nextProps.message);
                    // this.setState({
                    //     message: nextProps.result,
                    //     data: nextProps.data.data ? nextProps.data.data : false
                    // })
                    setTimeout(() => {
                        window.LadiUI.closeModalById('confirm-block-ip-form');
                        // const { actionObj } = this.state;
                        // const jsonData = {
                        //     "domain": actionObj.domain,
                        // }
                        // this.props.checkDomain(jsonData);
                    }, 500);
                    // setTimeout(function () {
                    //     window.LadiUI.closeModal(_seft.ref.current);
                    // },1000);

                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
            if (nextProps.action == types.CHECK_IP_FORM) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.status) {
                    // window.LadiUI.toastr('success', '', nextProps.message);
                    this.setState({
                        message: nextProps.message,
                    })
                } else {
                    window.LadiUI.toastr('error', '', nextProps.message);
                }
            }
        }
    }
    submit = (selectedID) => {

        this.selectedID = selectedID;

        window.LadiUI.showModalById('confirm-block-ip-form');

    }

    onChangeInput = (event) => {

        this.setState({
            actionObj: {
                ...this.state.actionObj,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    onChangeAction = (option) => {
        // console.log(option);
        this.setState({
            selectedAction: option,
        })
    }


    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })

    }
    blockDomain = () => {
        const { actionObj, selectedAction, note } = this.state;
        const jsonData = {
            "ip": actionObj.ip,
            "is_block": selectedAction.value,
            note
        }
        this.props.blockIpForm(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    checkIP = () => {
        const { actionObj, selectedAction, note } = this.state;
        const jsonData = {
            "ip": actionObj.ip,
        }
        this.props.checkIpForm(jsonData);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }


    render() {
        const { actionObj, message, data, selectedAction } = this.state;

        const title = 'Block IP form';
        const isLoadingDelete = this.props.loading && (this.props.waitting == types.DELETE_DOMAIN);
        return (
            <div ref={this.ref} id="modal-block-ip-form" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-block-ip-form">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-block-ip-form"><i className="ladi-icon icon-e-remove" data-dismiss="modal-block-ip-form" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">

                        <div className="ladiui-tab-content" style={{ height: "260px" }}>

                            <div className="ladiui-row">
                                <div className="col-50">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">IP:</label>
                                        <Input
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            name="ip"
                                            validationName="IP"
                                            placeholder="IP"
                                            value={actionObj.ip}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                </div>
                                <div className="col-50">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">Hành động</label>
                                        <div className="ladiui-select-options">

                                            <span className="ladiui-select-init">{
                                                selectedAction.name
                                            }</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.ACTION_IP_BLOCK, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeAction(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="ladiui-row" >


                                <div className="ladiui form-group">
                                    <strong>Note: </strong>
                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea} />
                                </div>

                            </div>

                        </div>


                        {
                            message &&
                            <label className="ladiui-label">{message}</label>
                        }
                        {
                            data && data.length > 0 &&

                            _.map(data, (item, index) =>
                                <label className="ladiui-label" key={index}>
                                    {"URL = " + item.url + " - Trạng thái: " + item.status}
                                </label>
                            )

                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-block-ip-form">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-block-ip-form" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.checkIP}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Kiểm tra trạng thái
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i>
                            Áp dụng
                        </button>

                    </div>
                </div>
                <ConfirmModal
                    id="confirm-block-ip-form"
                    title="Block ip form"
                    content="Hành động này rất quan trọng, bạn có chắc chắn thực hiện?"
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    onOk={() => this.blockDomain()}
                    isLoading={isLoadingDelete}
                />

            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        blockIpForm: (data) => dispatch(toolActions.blockIpForm(data)),
        checkIpForm: (data) => dispatch(toolActions.checkIpForm(data)),

    }
}

const mapStateToProps = (state) => ({ ...state.tool });

export default connect(mapStateToProps, mapDispatchToProps)(BlockDomain);