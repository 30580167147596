import config from './config';

export const status = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
}

export const endpoint = {
    //--------------------LUID------------------------
    ADMIN_SIGNIN: `${config.API_V2_0}/admin/signin`,
    CHANGE_PASSWORD: `${config.API_V2_0}/admin/change-password`,
    FORGOTTEN_PASSWORD: `${config.API_V2_0}/admin/forgotten-pass`,
    RESET_PASSWORD: `${config.API_V2_0}/admin/reset-password`,
    GET_ADMIN_INFO: `${config.API_V2_0}/admin/admin-info`,
    SIGNOUT: `${config.API_V2_0}/admin/signout`,
    COMPLETE_REGISTER: `${config.API_V2_0}/admin/complete-register`,

    // Sau nay se chuyen ham nay thanh user-info

    UPDATE_ADMIN_INFO: `${config.API_V2_0}/admin/update-info`,

    GET_URL_FACEBOOK: `${config.API_V2_0}/auth/facebook-generate-url`,
    GET_URL_GOOGLE: `${config.API_V2_0}/auth/google-generate-url`,
    OAUTH2_FACEBOOK: `${config.API_V2_0}/auth/oauth2-facebook`,
    OAUTH2_GOOGLE: `${config.API_V2_0}/auth/oauth2-google`,

    //--------------------USER-------------------------
    USER_LIST: `${config.API_V2_0}/user/list`,
    USER_LIST_REGISTER: `${config.API_V2_0}/user/list-register`,
    USER_ACTIVE_REGISTER: `${config.API_V2_0}/auth/active-register`,
    USER_LIST_BYDATE: `${config.API_V2_0}/user/list-bydate`,
    EXPORT_USER: `${config.API_V2_0}/file/export-user-bydate`,
    USER_INFO: `${config.API_V2_0}/user/show`,
    ADMIN_UPDATE_USER: `${config.API_V2_0}/user/update-info`,
    GET_TEMP_PASSWORD: `${config.API_V2_0}/user/get-token-login`,
    HARD_DELETE_USER: `${config.API_V2_0}/support/delete-user`,
    SESSION_LIST: `${config.API_V2_0}/support/time-onsite`,
    SESSION_USER_INFO: `${config.API_V2_0}/user/show`,
    //--------------------ADMIN-------------------------
    ADMIN_LIST: `${config.API_V2_0}/admin/list`,
    CREATE_ADMIN: `${config.API_V2_0}/admin/create`,
    ADMIN_UPDATE_ADMIN: `${config.API_V2_0}/admin/update`,
    GET_ROLE_LIST: `${config.API_V2_0}/role/list`,
    GET_HISTORY: `${config.API_V2_0}/admin/action-history`,
    //--------------------LS-------------------------
    MY_ORDER: `${config.API_V2_0}/order/my-order`,
    ORDER_LIST: `${config.API_V2_0}/order/list`,
    LIST_ORDER_DETAIL: `${config.API_V2_0}/order/list-detail`,
    ORDER_DELETE_IDS: `${config.API_V2_0}/order/delete-all`,
    ORDER_DELETE_ID: `${config.API_V2_0}/order/delete`,
    ORDER_CREATE: `${config.API_V2_0}/order/create`,
    ORDER_UPDATE: `${config.API_V2_0}/order/update`,
    ORDER_DETAIL: `${config.API_V2_0}/order/detail`,
    CANCEL_ORDER: `${config.API_V2_0}/order/update`,
    PAID_ORDER: `${config.API_V2_0}/order/update`,
    RE_CREATE_COMMISSION: `${config.API_V2_0}/support/recreate-commission`,

    //--------------------ORDER-------------------------
    LIST_PAYMENT: `${config.API_V2_0}/payment/list`,
    MY_PAYMENT: `${config.API_V2_0}/payment/my-payment`,
    CONFIRM_BANK: `${config.API_V2_0}/payment/confirm-bank`,

    //--------------------REFERRAL-------------------------
    REFERRAL_LIST: `${config.API_V2_0}/referral/list`,
    //--------------------AFILLATE-------------------------
    AFFILATE_LIST: `${config.API_V2_0}/affilate/list`,
    UPDATE_PAYMENT_INFO: `${config.API_V2_0}/affilate/update-my-info`,
    MY_AFFILATE_INFO: `${config.API_V2_0}/affilate/my-affilate-info`,
    ACTIVE_AFFILATE: `${config.API_V2_0}/affilate/create`,
    UPDATE_AFFILATE: `${config.API_V2_0}/affilate/update`,
    CHECK_AFFILATE_EMAIL: `${config.API_V2_0}/affilate/check-email`,
    CHECK_AFFILATE_CODE: `${config.API_V2_0}/affilate/check-code`,
    GET_AFFILATE_INFO: `${config.API_V2_0}/affilate/show`,
    //--------------------COMMISSION-------------------------
    COMMISSION_LIST: `${config.API_V2_0}/affilate/commission-list`,
    COMMISSION_LADIFLOW_LIST: `${config.API_V2_0}/affilate/commission-ladiflow-list`,
    COMMISSION_DETAIL: `${config.API_V2_0}/affilate/commission-detail`,
    EXPORT_COMMISSION: `${config.API_V2_0}/file/export-commission`,
    EXPORT_COMMISSION_LADIFLOW: `${config.API_V2_0}/file/export-commission-ladiflow`,
    //--------------------INVOICE-------------------------
    INVOICE_LIST: `${config.API_V2_0}/invoice/list-invoice`,
    INVOICE_DETAIL: `${config.API_V2_0}/invoice/detail`,
    PAID_INVOICE: `${config.API_V2_0}/invoice/paid-invoice`,
    PAID_ALL_INVOICE: `${config.API_V2_0}/invoice/paid-list-invoice`,
    EXPORT_INVOICE: `${config.API_V2_0}/file/export-invoice`,
    //--------------------PACKAGE-------------------------
    LIST_PACKAGE: `${config.API_V2_0}/support/list-packages`,
    LIST_PACKAGE_LADIBOOST: `${config.API_V2_0}/support/list-packages-ladiboost`,
    LIST_PACKAGE_COURSE: `${config.API_V2_0}/support/list-packages-course`,
    //--------------------POINT---------------------------
    LIST_POINT_LOGS: `${config.API_V2_0}/point/list-by-user`,
    MY_POINT_LOGS: `${config.API_V2_0}/point/my-point-log`,
    CHANGE_POINT: `${config.API_V2_0}/point/change-point`,
    //--------------------PARTNER---------------------------
    LIST_CREDIT_LOGS: `${config.API_V2_0}/partner/list-by-user`,
    MY_CREDIT_LOGS: `${config.API_V2_0}/partner/my-credit-log`,
    CHANGE_CREDIT: `${config.API_V2_0}/partner/change-credit`,
    CREATE_PARTNER: `${config.API_V2_0}/partner/create`,
    REPORT_CREDIT_LOGS: `${config.API_V2_0}/report/get-report-credit`,
    EXPORT_CREDIT_LOG: `${config.API_V2_0}/file/export-credit-log`,
    EXPORT_REPORT_APOTA: `${config.API_V2_0}/file/export-report-apota`,
    REPORT_TRAFFIC: `${config.API_V2_0}/admin/report-traffic`,

    //--------------------TOOL---------------------------
    MOVE_LADIPAGE: `${config.API_V2_0}/admin/move-landing-page`,
    MOVE_ALL_LADIPAGE: `${config.API_V2_0}/admin/move-all-landing-page`,
    CHECK_DOMAIN: `${config.API_V2_0}/admin/check-domain`,
    CHECK_LADI_LINK: `${config.API_V2_0}/admin/check-ladi-link`,
    DELETE_MEMBER_LP: `${config.API_V2_0}/admin/delete-member-lp`,
    RESET_MEMBER_LP: `${config.API_V2_0}/admin/reset-member-lp`,
    DELETE_DOMAIN: `${config.API_V2_0}/support/delete-domain`,
    BLACK_LIST_DOMAIN: `${config.API_V2_0}/support/blacklist-domain`,
    ADD_DOMAIN_WHITELIST: `${config.API_V2_0}/support/whitelist-domain`,
    CHANGE_EMAIL: `${config.API_V2_0}/admin/change-email`,
    RESTORE_LADIPAGE: `${config.API_LADIPAGE_V2_0}/point/change-point`,

    ACTION_SSL: `${config.API_V2_0}/support/action-domain-ssl`,
    CHECK_DOMAIN_SSL: `${config.API_V2_0}/support/check-domain-ssl`,
    VERIFY_EMAIL: `${config.API_V2_0}/support/change-verify-email`,
    VERIFY_EMAIL_LADIFLOW: `${config.API_V2_0}/support/change-verify-email-flow`,
    VERIFY_DOMAIN: `${config.API_V2_0}/support/change-domain-verify`,
    VERIFY_DOMAIN_NEW: `${config.API_V2_0}/support/vefify-domain`,
    STOP_URL: `${config.API_V2_0}/support/stop-url`,
    ACTIVE_COURSE: `${config.API_V2_0}/support/active-course`,
    RELOAD_CONFIG_SSL: `${config.API_V2_0}/support/reload-config-ssl`,
    RECONNECT_REDIS: `${config.API_V2_0}/support/reconnect-redis`,

    BLOCK_DOMAIN: `${config.API_V2_0}/admin/block-domain`,
    BLOCK_IP_FORM: `${config.API_V2_0}/admin/block-ip-form`,
    CHECK_IP_FORM: `${config.API_V2_0}/admin/check-ip-form`,
    BLOCK_LADI_LINK: `${config.API_V2_0}/admin/block-ladi-link`,
    BLOCK_URL: `${config.API_V2_0}/admin/block-url`,
    BLOCK_PUBLISH: `${config.API_V2_0}/admin/block-publish`,
    ADD_STORAGE: `${config.API_V2_0}/support/add-storage`,
    REMOVE_KEY_REDIS: `${config.API_V2_0}/support/remove-key-redis`,
    REPORT_APOTA: `${config.API_V2_0}/file/remove-key-redis`,


    //--------------------REPORT---------------------------
    LIST_REPORT_DAILY: `${config.API_V2_0}/report/list`,
    LIST_REPORT_MONTHLY: `${config.API_V2_0}/report/get-report-monthly`,
    LIST_REPORT_REVENUE: `${config.API_V2_0}/report/get-report-revenue`,
    //--------------------STORE---------------------------
    LIST_STORE: `${config.API_V2_0}/support/get-stores`,
    LIST_DEALS: `${config.API_V2_0}/support/get-deals`,
    UPGRADE_STORE: `${config.API_V2_0}/support/upgrade-store`,
    INFO_LADIBOOST: `${config.API_V2_0}/support/info-ladiboost`,
    ACTIVE_LADIBOOST: `${config.API_V2_0}/support/active-ladiboost`,
    UPGRADE_STORE_LIST: `${config.API_V2_0}/support/upgrade-store-list`,
    DOWNGRADE_STORE: `${config.API_V2_0}/support/downgrade-store`,
    CLEAR_TOKEN: `${config.API_V2_0}/support/clear-token`,
    GET_USERINFO: `${config.API_V2_0}/admin/get-userinfo`,
    ACTIVE_STORE: `${config.API_V2_0}/support/active-store`,
    CHANGE_PACKAGE_STORE: `${config.API_V2_0}/support/update-store-info`,
    CHANGE_VERIFY_REQUIRED: `${config.API_V2_0}/admin/change-required-verify`,
    //--------------------ACTIVITY-------------------------
    ACTIVITY_LIST: `${config.API_V2_0}/activity/get-list`,
    EXPORT_ACTIVITY: `${config.API_V2_0}/file/export-activity`,

}

export default {
    KEY_CAPCHA: "6LcpnAEVAAAAACgBWq2ZN831dLOp22-A-KPCWYuE",
    ADMIN_INFO: 'gtKcw5KTsS-VsyyMykVOb',
    LOGO: "https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-white-1558579165.svg",
    DEFAULT_FORMAT_DATE: 'DD/MM/YYYY HH:mm:ss',
    DEFAULT_AVATAR: 'https://static.ladipage.net/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg',
    COOKIE_EXPRIRE: 7,
    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS: [
            { value: 2, name: 2 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
            { value: 200, name: 200 },
        ]
    },
    IS_TESTING: {
        YES: { value: true, name: 'Giao dịch TEST' },
        NO: { value: false, name: 'Giao dịch THỰC TẾ' }
    },
    IS_LADIFLOW_AFFILIATE: {
        YES: { value: true, name: 'Có tạo hoa hồng' },
        NO: { value: false, name: 'Không tạo hoa hồng' }
    },
    PAYMENT_RETURN_OPTIONS: {
        RETURNED: { value: 'RETURN', name: 'Trừ số tháng cũ' },
        NO_RETURNED: { value: 'NO_RETURN', name: 'Không trừ số tháng cũ' }
    },
    PAYMENT_STATUS_OPTIONS: {
        ALL: { value: '', name: 'Tất cả trạng thái' },
        200: { value: '', name: 'Đã thanh toán' },
        PENDING: { value: 'PENDING', name: 'Chờ thanh toán' },
        PAID: { value: 'PAID', name: 'Đã thanh toán' },
        PAID_DEBIT: { value: 'PAID_DEBIT', name: 'Chuyển khoản sau' },
        CANCEL: { value: 'CANCEL', name: 'Đã huỷ' },
        INVALID: { value: 'INVALID', name: 'Không hợp lệ' },
    },
    PAYMENT_TYPE_OPTIONS: {
        ALL: { value: '', name: 'Tất cả' },
        PARTNER: { value: 'PARTNER', name: 'Partner' },
        ADMIN: { value: 'ADMIN', name: 'Admin' }
    },
    PACKAGE_OPTIONS: {
        ALL: { value: '', name: 'All' },
        LP: { value: 'lp', name: 'Ladi Page' },
        LUID: { value: 'luid', name: 'Ladi UID' },
        LS: { value: 'ls', name: 'Ladi Sale' },
        LA: { value: 'la', name: 'Ladi Analytics' },

    },
    PAYMENT_METHOD_OPTIONS: {
        ALL: { value: '', name: 'Phương thức thanh toán' },
        ONLINE_METHOD: { value: 'ONLINE_METHOD', name: 'Cổng thanh toán' },
        OFFLINE_METHOD: { value: 'OFFLINE_METHOD', name: 'Chuyển khoản' }
    },
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW'
    },
    SORT_BY: {
        ASCENDING: 1,
        DESCENDING: -1
    },
    APP: {
        LP: 'Ladi Page',
        LS: 'Ladi Sales',
        LUID: 'Ladi UID',
        LC: 'Ladi Chat',
        LA: 'Ladi Analytics',
        LADS: 'Ladi Advertisement',
        LSHARE: 'Ladi Share',
        LLINK: 'Ladi Link'
    },
    AFFILATE_LEVEL: {
        ALL: { value: '', name: 'Tất cả level' },
        SILVER: { value: 'SILVER', name: 'Silver(0-50)' },
        GOLD: { value: 'GOLD', name: 'Gold(51-100)' },
        PREMIUM: { value: 'PREMIUM', name: 'Premium(100+)' }
    },
    REFERRAL_AMOUNT: {
        ALL: { value: '', name: 'All' },
        // LV_1: { value: 1, name: 'Lv 1' },
        // LV_2: { value: 2, name: 'Lv 2' },
        // LV_3: { value: 3, name: 'Lv 3' },
        // LV_4: { value: 4, name: 'Lv 4' },
    },
    COMMISSION_TYPE: {
        ALL: { value: '', name: 'Mọi trạng thái' },
        INVOICED: { value: 'INVOICED', name: 'Đã lập hoá đơn' },
        NOT_INVOICED: { value: 'NOT_INVOICED', name: 'Chưa lập hoá đơn' }
    },
    USER_STATUS: {
        ACTIVE: { value: true, name: 'Hoạt động' },
        DEAD_ACTIVE: { value: false, name: 'Đã khoá' }
    },
    USER_REGISTER_TYPE: {
        ALL: { value: 'ALL', name: 'Tất cả' },
        AFFILATE: { value: 'IS_AFFILATE', name: 'Có mã Ref' },
        NORMAL: { value: 'NOT_AFFILATE', name: 'Không có mã Ref' }
    },
    USER_TYPE: {
        ALL: { value: 'ALL', name: 'Tất cả' },
        PARTNER: { value: 'IS_PARTNER', name: 'Partner ship' },
        NOT_PARTNER: { value: 'NOT_PARTNER', name: 'Thông thường' }
    },
    INVOICE_STATUS: {
        ALL: { value: '', name: 'Mọi trạng thái' },
        PENDING: { value: 'PENDING', name: 'Chưa thanh toán' },
        PAID: { value: 'PAID', name: 'Đã thanh toán' },
        CANCEL: { value: 'CANCEL', name: 'Đã huỷ' },
    },
    SUPPORT_URL: {
        SUPPORT: 'https://builder.ladipage.com',
        DOCUMENT: 'https://docs.ladipage.com',
        NEWS: 'https://www.facebook.com/groups/LadiPageVietnam',
        SHORT_CUT: 'https://builder.ladipage.com',
        FANPAGE_FACEBOOK: 'https://www.facebook.com/groups/LadiPageVietnam'

    },
    ROLE: {
        ADMIN: 'ADMIN',
        SUPPORT: 'SUPPORT',
        SUPPORT_TOOL: 'SUPPORT_TOOL',
        SUPPORT_MANAGER: 'SUPPORT_MANAGER',
        SALE: 'SALE',
        AFFILATE_CARE: 'AFFILATE_CARE'
    },
    STORE_CONFIG: {
        LP_APP_CODE: {
            WebsiteBuilder: 'WebsiteBuilder',
            Ecommerce: 'Ecommerce',
            BlogNewsletter: 'BlogNewsletter',
            BannerAds: 'BannerAds',
            PageAccess: 'PageAccess',
            LinkBuilder: 'LinkBuilder',
            Course: 'Course',
            FunnelX: 'FunnelX'
        },
        APP_CODE: {
            LP: { value: 'LP', name: 'Ladi Page' },
            LP_APP: { value: 'LP_APP', name: 'Ladi Page App' },
            LS: { value: 'LS', name: 'Ladi Sales' },
            LC: { value: 'LC', name: 'Ladi Chat' },
            LSHARE: { value: 'LSHARE', name: 'Ladi Share' },
            LF: { value: 'LF', name: 'Ladi Flow' },
            LLINK: { value: 'LLINK', name: 'Ladi Link' },
        },
        TYPE: {
            ALL: { value: '', name: 'Mọi trạng thái' },
            EXPRIRE: { value: true, name: 'Đã hết hạn' },
            NOT_EXPRIRE: { value: false, name: 'Chưa hết hạn' }
        }
        // LP: {
        //     GET_LIST:`${config.API_LADISALE_V2_0}/admin/signin`
        // },
        // LS: {
        //     GET_LIST:`${config.API_LADISALE_V2_0}/admin/signin`
        // }
    },
    FREE_PACKAGE: "FREE",
    EXPRIRE_PACKAGES_TIME: {
        WEEK: { value: 7, name: '1 tuần' },
        HALF_MONTH: { value: 15, name: '15 ngày' },
        MONTH: { value: 30, name: '1 Tháng' }
    },
    DEFAULT_FORMAT_DATE_NEW: 'DD/MM/YYYY',
    ACTION_TYPE: {
        ALL: { value: '', name: 'Mọi thao tác' },
        UPGRADE_STORE: { value: 'UPGRADE_STORE', name: 'Nâng cấp/ gia hạn store' },
        CREATE_PARTNER: { value: 'CREATE_PARTNER', name: 'Active tài khoản partner' },
        CREATE_AFFILATE: { value: 'CREATE_AFFILATE', name: 'Tạo tài khoản affilate' },
        RE_CREATE_COMMISSION: { value: 'RE_CREATE_COMMISSION', name: 'Gắn affilate và tạo commission' },
        UPDATE_AFFILATE: { value: 'UPDATE_AFFILATE', name: 'Update thông tin affilate' },
        ACTIVE_STORE_LS: { value: 'ACTIVE_STORE_LS', name: 'Tạo store LadiSales' },
        DOWNGRADE_STORE: { value: 'DOWNGRADE_STORE', name: 'Thay đổi thông tin store' },
        CREATE_ADMIN: { value: 'CREATE_ADMIN', name: 'Tạo tài khoản Admin' },
        UPDATE_ADMIN: { value: 'UPDATE_ADMIN', name: 'Thay đổi thông tin Admin' },
        UPDATE_USER: { value: 'UPDATE_USER', name: 'Thay đổi thông tin user' },
        CHANGE_EMAIL: { value: 'CHANGE_EMAIL', name: 'Đổi email' },
        MOVE_LADIPAGE: { value: 'MOVE_LADIPAGE', name: 'Di chuyển ladipage' },
        PAID_INVOICE: { value: 'PAID_INVOICE', name: 'Tất toán hoá đơn' },
        ADD_POINT: { value: 'ADD_POINT', name: 'Cộng point vào tài khoản' },
        ADD_CREDIT: { value: 'ADD_CREDIT', name: 'Cộng credit vào tài khoản' },
        GET_TOKEN: { value: 'GET_TOKEN', name: 'Lấy token API' },
        DELETE_DOMAIN: { value: 'DELETE_DOMAIN', name: 'Xoá domain' },
        BLACK_LIST_DOMAIN: { value: 'BLACK_LIST_DOMAIN', name: 'Black list domain' },
        BLOCK_TRACKING_IP_FORM: { value: 'BLOCK_TRACKING_IP_FORM', name: 'Black list ip form' },
        ADD_STORAGE: { value: 'ADD_STORAGE', name: 'Cộng dung lượng upload' },


    },
    ACTION_BLOCK_STATUS: {
        block: { value: true, name: 'Chặn' },
        unblock: { value: false, name: 'Bỏ chặn' },
    },
    ACTION_SSL: {
        onssl: { value: 'onssl', name: 'Bật SSL' },
        offssl: { value: 'offssl', name: 'Tắt SSL' },
    },
    ACTION_DOMAIN_BLACKLIST: {
        addBlackList: { value: true, name: 'Thêm vào blacklist' },
        removeBlackList: { value: false, name: 'Xóa khỏi blacklist' },
    },
    ACTION_DOMAIN_BLOCK: {
        addBlackList: { value: true, name: 'Block domain' },
        removeBlackList: { value: false, name: 'Unblock domain' },
    },
    CHANGE_VERIFY_REQUIRED: {
        needRequired: { value: true, name: 'Bắt buộc xác thực tài khoản' },
        notRequired: { value: false, name: 'KHÔNG bắt buộc xác thực tài khoản' },
    },
    ACTION_IP_BLOCK: {
        addBlackList: { value: true, name: 'Block IP' },
        removeBlackList: { value: false, name: 'Unblock IP' },
    },
    ACTION_DOMAIN_WHITELIST: {
        addWhiteList: { value: true, name: 'Add domain whitelist' },
        removeWhiteList: { value: false, name: 'Remove domain whitelist' },
    },
    EMAIL_VERIFY_STATUS: {
        ACTIVE: { value: true, name: 'Đã xác thực' },
        DEAD_ACTIVE: { value: false, name: 'Mail chưa xác thực' }
    },
    DOMAIN_VERIFY_TYPE: {
        DOMAIN: { value: 'DOMAIN', name: 'Domain' },
        BLOG: { value: 'BLOG', name: 'Blog' },
        WEBSITE: { value: 'WEBSITE', name: 'Website' },
    },
    REMOVE_KEY_REDIS_TYPE: {
        LIMIT_VISIT: { value: 'LIMIT_VISIT', name: 'Limit visit' },
        LP_USER: { value: 'LP_USER', name: 'User' }
    },
    PKG_TIME_ONSITE: {
        FREE: { value: 'FREE', name: 'FREE' },
        PRO: { value: 'PRO', name: 'PRO' },
        ENTERPRISE: { value: 'ENTERPRISE', name: 'ENTERPRISE' },
    },
    ALLOW_ADMIN: [
        '5d5256208db3e139ed4d0ee8',
        '5d3149dd07c0a56388b1a889'
    ],
    MONEY_PACKAGES: {
        PACK_1: { value: 500000, name: '500,000', bonus: 0 },
        PACK_2: { value: 1000000, name: '1,000,000', bonus: 0 },
        PACK_3: { value: 2000000, name: '2,000,000', bonus: 0 },
        PACK_4: { value: 5000000, name: '5,000,000', bonus: 0 },
        PACK_5: { value: 10000000, name: '10,000,000', bonus: 0 },
    },
    CREDIT_PACKAGES: {
        PACK_1: { value: 20000, name: '20,000', bonus: 0 },
        PACK_2: { value: 50000, name: '50,000', bonus: 10 },
        PACK_3: { value: 100000, name: '100,000', bonus: 20 },
        PACK_4: { value: 200000, name: '200,000', bonus: 30 },
    },
    CREDIT_TYPES: {
        PARTNER: { value: 'PARTNER', name: 'Partner credit' },
        APP: { value: 'APP', name: 'Điểm' },
        MONEY: { value: 'MONEY', name: 'Tiền' }
    },
    MOVE_ALL_LADIPAGE: {
        COPY: { value: 'COPY', name: 'Nhân bản' },
        MOVED: { value: 'MOVE', name: 'Chuyển sang toàn bộ' }
    },
    MOVE_ALL_LADIPAGE_DELETE: {
        NOT_DELETE: { value: false, name: 'Chưa xóa' },
        DELETE: { value: true, name: 'Đã xóa' },

    },
    VERIFY_LADIUID: {
        NOT_VERIFY: { value: 'NOT_VERIFY', name: 'Không verify bên LADIUID' },
        VERIFIED: { value: 'VERIFIED', name: 'Có verify LADIUID' }
    },
    CREDIT_LOG_TYPE: {
        ALL: { value: '', name: 'Tất cả' },
        USER_BUY_ORDER: { value: 'USER_BUY_ORDER', name: 'Mua gói' },
        ADMIN_ADD: { value: 'ADMIN_ADD', name: 'Nạp Partner Credits' },
        APP_DEPOSIT: { value: 'APP_DEPOSIT', name: 'Nạp vào Điểm' },
        APP_PURCHASED: { value: 'APP_PURCHASED', name: 'Sử dụng Điểm' },

        MONEY_ADD: { value: 'MONEY_ADD', name: 'Nạp tiền' },
        MONEY_PURCHASED: { value: 'MONEY_PURCHASED', name: 'Trả phí' },

    }
}