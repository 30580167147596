import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import baseHelper from '../helpers/BaseHelper';
import appConst from '../config/const';

const SubmenuLi = {
    user: <li key="/user" ><NavLink key='userlist' to="/user">Danh sách</NavLink></li>,
    "user-detail": <li key="/user-detail" ><NavLink key='userdetail' to="/user-detail">Danh sách theo ngày</NavLink></li>,
    "user-register": <li key="/user-register" ><NavLink key='userregister' to="/user-register">Danh sách đăng kí mới</NavLink></li>,
    timeonsite: <li key="/timeonsite" ><NavLink key='timeonsite' to="/timeonsite">Time Onsite</NavLink></li>,
    store: <li key="/store" ><NavLink key='store' to="/store" >Danh sách store</NavLink></li>,
    "activity-log": <li key="/activity-log" ><NavLink key='activity-log' to="/activity-log" >Lịch sử hoạt động</NavLink></li>,
    affilate: <li key="/affilate" ><NavLink key='affilateList' to="/affilate">Danh sách</NavLink></li>,
    partner: <li key="/partner" ><NavLink key='partnerList' to="/partner">Báo cáo Partner</NavLink></li>,
    commission: <li key="/commission" ><NavLink key='commission' to="/commission" >Hoa hồng</NavLink></li>,
    commission_flow: <li key="/commission-flow" ><NavLink key='commission-flow' to="/commission-flow" >Hoa hồng LadiFlow</NavLink></li>,
    invoice: <li key="/invoice" ><NavLink key='invoice' to="/invoice" >Hoá đơn</NavLink></li>,
    admin: <li key="/admin" ><NavLink key='admin' to="/admin" >Danh sách</NavLink></li>,
    history: <li key="/history" ><NavLink key='history' to="/history" >Lịch sử</NavLink></li>

};

export default class SidebarItem extends React.Component {
    static propTypes = {
        keyStr: PropTypes.array,
        classLi: PropTypes.string,
        classNav: PropTypes.string,
        classIcon: PropTypes.string,
        to: PropTypes.string,
        title: PropTypes.string,

    }

    constructor(props) {
        super(props);
    }


    onClick = () => {

    }

    render() {
        const { classLi, classNav, classIcon, to, title, keyStr, subMenu, subItem } = this.props;

        const checkPath = window.location.pathname.replace('/', '');
        const adminInfo = baseHelper.getCookie(appConst.ADMIN_INFO);
        const classOpen = (keyStr.indexOf(checkPath) >= 0) ? classLi + " open" : classLi;

        if (!subMenu) {

            return (
                <li className={classOpen} >
                    <NavLink to={to} className={classNav}> <i className={classIcon} /> {title} </NavLink>
                </li>
            );
        }
        const itemLi = {};
        // console.log(adminInfo.scopes);
        _.map(SubmenuLi, (value, scope) => {
            const condition = (adminInfo.scopes[scope] || adminInfo.scopes.admin) && subItem.indexOf(scope) >= 0;
            if (condition)
                itemLi[scope] = value;
        })

        return (
            <li className={classOpen} >
                <a className={classNav}> <i className={classIcon} /> {title}</a>
                <ul key={new Date().getTime() + "_submenu"} className="ladiui submenu"
                    onClick={(event) => {
                        if (document.body.classList.contains('mini-navbar')) {
                            event.currentTarget.classList.remove('show');
                        }
                    }}>
                    <li></li>
                    {
                        _.map(itemLi, (item, key) =>
                            item
                        )
                    }
                </ul>
            </li>

        );
    }
}
