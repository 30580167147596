import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const list = (data) => ({
    type: types.USER_LIST,
    meta: {
        endpoint: endpoint.USER_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listRegister = (data) => ({
    type: types.USER_LIST_REGISTER,
    meta: {
        endpoint: endpoint.USER_LIST_REGISTER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const activeRegister = (data) => ({
    type: types.USER_ACTIVE_REGISTER,
    meta: {
        endpoint: endpoint.USER_ACTIVE_REGISTER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const listBydate = (data) => ({
    type: types.USER_LIST_BYDATE,
    meta: {
        endpoint: endpoint.USER_LIST_BYDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const exportExcel = (data) => ({
    type: types.EXPORT_USER,
    meta: {
        endpoint: endpoint.EXPORT_USER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updateUser = (data) => ({
    type: types.ADMIN_UPDATE_USER,
    meta: {
        endpoint: endpoint.ADMIN_UPDATE_USER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const userInfo = () => ({
    type: types.USER_INFO,
    meta: {
        endpoint: endpoint.USER_INFO,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});


const getTempPassword = (body) => ({
    type: types.GET_TEMP_PASSWORD,
    meta: {
        endpoint: endpoint.GET_TEMP_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

const deleteUser = (body) => ({
    type: types.HARD_DELETE_USER,
    meta: {
        endpoint: endpoint.HARD_DELETE_USER,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});


export default {
    list,
    listRegister,
    activeRegister,
    listBydate,
    exportExcel,
    userInfo,
    updateUser,
    deleteUser,
    getTempPassword
}