import React from 'react';
import toolActions from '../../redux/futures/tools/actions';
import userActions from '../../redux/futures/user/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import * as userTypes from '../../redux/futures/user/types';

import * as types from '../../redux/futures/tools/types';




class CheckDomain extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            domain: '',
            typing: false,
            typingTimeout: 0,
            loading: false,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary",
            mode: '',
            token: false,
            message: ""
        };
        this.inputsRef = new Set();
        this.ref = React.createRef();
        this.textAreaRef = React.createRef();
        this.initialState = _.cloneDeep(this.state);
        // this.defaultState = _.cloneDeep(this.state.user);
    }


    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if (nextProps.tool.action == types.CHECK_DOMAIN) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.tool.status) {
                    window.LadiUI.toastr('success', '', nextProps.tool.message);
                    this.setState({
                        message: nextProps.tool.result
                    })
                } else {
                    window.LadiUI.toastr('error', '', nextProps.tool.message);
                }
            }
            if (nextProps.user.action == userTypes.GET_TEMP_PASSWORD) {
                this.setState({
                    loading: false,
                    classApply: this.state.classNormal
                });
                if (nextProps.user.status) {

                    this.setState({
                        token: nextProps.user.token
                    }, () => {

                        this.textAreaRef.select();
                        document.execCommand('copy');
                        window.LadiUI.toastr('success', '', 'Đã copy vào clipboard');

                    })
                } else {
                    window.LadiUI.toastr('error', '', nextProps.user.message);
                }
            }
        }
    }

    submit = () => {

        // Validate
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            // console.log(ref);
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }
        const { domain } = this.state;
        const jsonData = {
            domain
        }
        this.props.checkDomain(jsonData);

        this.state.loading = true;
        this.state.classApply = this.state.classLoading;

    }


    openGetPassword = () => {
        const { message } = this.state;
        let ladiUID = false;
        if (message.indexOf('LadiUID:') > 0) {
            let arr = message.split('\n');
            arr = arr[0].split('LadiUID:');
            ladiUID = arr[1].split(',')[0];
            let email = arr[1].split(',')[1];
            email = email.trimLeft();
            this.props.getToken({ ladi_uid: ladiUID, email });
        }
    }

    onChangeInput = (event) => {

        this.setState({
            domain: event.target.value
        });
    }


    resetForm = (event) => {
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })

    }

    render() {
        const { domain, message, token } = this.state;


        const title = 'Kiểm tra tên miền';
        const isLoadingPassword = this.props.user.loading && (this.props.user.waitting == userTypes.GET_TEMP_PASSWORD);
        const isLoadingDomain = this.props.user.loading && (this.props.user.waitting == types.CHECK_DOMAIN);

        return (
            <div ref={this.ref} id="modal-check-domain" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-check-domain">
                <div className="ladiui-modal-content" >
                    <div className="ladiui-modal-header"><button type="button" className="ladiui-close-modal" data-dismiss="modal-check-domain"><i className="ladi-icon icon-e-remove" data-dismiss="modal-check-domain" /></button>
                        <h3>{title}</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        {/* <form> */}
                        <div className="ladiui-tab-content">
                            <div className="ladiui form-group">
                                <label className="ladiui-label">Tên miền</label>
                                <Input
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    name="domain"
                                    validationName="Domain"
                                    placeholder="Tên miền"
                                    value={domain}
                                    onChange={this.onChangeInput}
                                    validations={{ isRequired: true }}
                                />

                            </div>
                            {token &&
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">Token đăng nhập</label>
                                    <input
                                        readOnly
                                        type="text"
                                        className="ladiui input"
                                        value={token}
                                        ref={(textarea) => this.textAreaRef = textarea}
                                    />
                                </div>
                            }
                        </div>
                        {/* </form> */}
                        {
                            message &&
                            <label className="ladiui-label">{message}</label>
                        }

                    </div>

                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-check-domain">
                            <i className="ladi-icon icon-e-remove" data-dismiss="modal-check-domain" />Đóng
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.openGetPassword}
                            disabled={isLoadingPassword}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (isLoadingPassword) ? 'none' : '', paddingRight: '5px' }}></i>  Lấy mật khẩu
                        </button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={isLoadingDomain}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (isLoadingDomain) ? 'none' : '', paddingRight: '5px' }}></i>
                            Tìm kiếm domain
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkDomain: (data) => dispatch(toolActions.checkDomain(data)),
        getToken: (data) => dispatch(userActions.getTempPassword(data)),

    }
}

const mapStateToProps = (state) => ({ tool: { ...state.tool }, user: { ...state.user } });

export default connect(mapStateToProps, mapDispatchToProps)(CheckDomain);