import React from 'react';
import * as types from '../../../redux/futures/store/types';
import storeActions from '../../../redux/futures/store/actions';
import _ from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';
import CustomDatePicker from "../../../components/DatePicker/CustomDatePicker";



class ChangePackageStore extends React.Component {

    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            store: {
                store_id: '',
                name: '',
                ladi_uid: '',
                pkg_name: '',
                pkg_exp: '',
                pkg_buy_exp: '',
                pkg_buy_price: '0',
                created_at: '',
                web_id: ''
            },
            userinfo: {
                point: '',
                email: '',
                fullname: '',
                lastname: '',
                phone: ''
            },
            exprireDate: date,
            activedDate: date,
            type: props.typeUpgrade,
            note: '',
            affilateCode: '',
            appCode: 'LP',
            packages: [],
            selectedPackage: '',
            month: "3",
            addition_month: "0",
            discount: "0",
            point: "0",
            totalPrice: 0,
            backMoney: 0,
            method: appConst.PAYMENT_STATUS_OPTIONS.PAID.value,
            returned: appConst.PAYMENT_RETURN_OPTIONS.RETURNED.value,
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({ appCode: nextProps.appCode })
        }

        if (this.state.type !== nextProps.typeUpgrade) {
            this.setState({ type: nextProps.typeUpgrade })
        }

        if (this.props.currentStore !== nextProps.currentStore) {
            if (!nextProps.currentStore) {
                this.resetForm();
            } else {
                // console.log('nextProps.currentStore ', nextProps.currentStore);
                let { pkg_actived_at, pkg_exp, pkg_name, created_at } = nextProps.currentStore;
                if (!pkg_actived_at) {
                    pkg_actived_at = new Date();
                } else {
                    pkg_actived_at = new Date(pkg_actived_at);
                }
                if (!pkg_exp) {
                    pkg_exp = new Date();
                } else {
                    pkg_exp = new Date(pkg_exp);
                }
                this.setState({
                    store: _.pick(nextProps.currentStore, _.keys(this.state.store)),
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    activedDate: pkg_actived_at,
                    exprireDate: pkg_exp,
                    packages: [],
                    pkg_name: pkg_name
                })
            }
        }

        if (nextProps.action === types.GET_USERINFO) {
            // this.setState({loaderPackages: false});
            // console.log(nextProps.userinfo);
            if (nextProps.status && nextProps.userinfo) {
                this.setState({
                    userinfo: nextProps.userinfo
                });
            } else {
                // window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                const { store } = this.state;
                const expDate = new Date(store.pkg_exp);
                const isExp = baseHelper.diffDateDay(expDate, new Date()) > 0;

                let selectedPackage = null;
                let list = [];
                // window.LadiUI.toastr('success', '', nextProps.message);
                for (let i = 0; i < nextProps.packages.length; i++) {
                    // if (nextProps.packages[i].price == 0) {
                    list.push(nextProps.packages[i]);
                    // }
                }
                // if (price) {
                for (let i = 0; i < list.length; i++) {
                    if (store.pkg_name == list[i].name) {
                        selectedPackage = list[i];
                        break;
                    }
                }
                // } else {
                // list = nextProps.packages;
                // }

                list = list.sort(function (a, b) {
                    if (a.price > b.price) return 1;
                    if (a.price < b.price) return -1;
                    return 0;
                });
                if (!selectedPackage) {
                    selectedPackage = list[0];
                }
                // console.log(list, selectedPackage);
                this.setState({
                    packages: list,
                    selectedPackage: selectedPackage,
                    loaderPackages: false
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }
        if (nextProps.action == types.CHANGE_PACKAGE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal
            });
            if (nextProps.status) {
                const _seft = this;
                window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    pickPackages(name) {
        const { packages } = this.state;
        console.log('packages:', packages);
        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {

                this.setState({
                    selectedPackage: packages[i]
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }


    changeActivedate = (date) => {
        this.setState({
            activedDate: date
        }, () => {
            // this.getList();
        });

    }

    changeExpriredate = (date) => {
        this.setState({
            exprireDate: date
        }, () => {
            // this.getList();
        });

    }


    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { store, selectedPackage, activedDate, exprireDate, appCode } = this.state;


        const data = {
            ladi_uid: store.ladi_uid,
            store_id: store.store_id,
            name: store.name,
            package_name: selectedPackage.package_name,
            app_code: appCode,
            package_active_at: activedDate,
            package_exp: exprireDate,
        };

        console.log(data); //return;
        this.props.changePackageStore(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }


    onChangePackage = (event) => {
        this.pickPackages(event.target.value);
    }



    handleClickOutside = () => {
        this.props.onCancel();
    }

    render() {
        this.inputsRef = new Set();
        const { store, packages, selectedPackage, loaderPackages, userinfo, backMoney, appCode } = this.state;
        let title = "Nâng cấp hoặc gia hạn store: " + userinfo.email;
        if (userinfo.is_partner_aff) {
            title += " - Mã Partner : " + userinfo.affilate_code;
        }
        let appName = appConst.STORE_CONFIG.APP_CODE[appCode].name;
        return (
            <div ref={this.ref} id="modal-change-store-package" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-change-store-package" style={{ zIndex: 21 }} >
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-change-store-package"><i className="ladi-icon icon-e-remove" data-dismiss="modal-change-store-package" onClick={this.props.onCancel} /></button>
                        <h3>{title}<span className="app-name">{appName}</span></h3>
                    </div>
                    <div className={"ladiui-modal-body"}>

                        <div className="ladiui-row" >
                            <div className="col-50" style={{ height: "40px" }} >
                                <strong>Tên store: </strong>
                                {
                                    store.name
                                }
                            </div>


                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Ladi UID: </strong>
                                {
                                    store.ladi_uid
                                }
                            </div>
                        </div>
                        <div className="ladiui-row"  >
                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Tên gói: </strong>{store.pkg_name}
                            </div>
                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Ngày hết hạn: </strong>{baseHelper.formatStrToDate(store.pkg_exp)}
                                {' (' + baseHelper.numberWithCommas(backMoney) + ')'}
                            </div>
                        </div>
                        <form>

                            <div>
                                <div className="ladiui-row" >
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Ngày hết hạn</h3>
                                        </div>
                                        <div className="ladiui form-group">
                                            <CustomDatePicker
                                                customClass="ladiui input date"
                                                selected={this.state.exprireDate}
                                                // onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.changeExpriredate} //only when value has changed
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Ngày kích hoạt</h3>
                                        </div>
                                        <div className="ladiui form-group">
                                            <CustomDatePicker
                                                customClass="ladiui input date"
                                                selected={this.state.activedDate}
                                                // onSelect={this.handleSelect} //when day is clicked
                                                onChange={this.changeActivedate} //only when value has changed
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui form-group condition-form">

                                    <h3>Lựa chọn gói dịch vụ</h3>
                                </div>
                                <div className="ladiui-row">
                                    {
                                        _.map(packages, (packageObj, idx) => {
                                            // if (packageObj.price != undefined && packageObj.price == 0) {
                                            //     return <div key={"package-" + packageObj.name}></div>
                                            // }
                                            let displayName = packageObj.name;
                                            if (packageObj.package_name == 'PREMIUM_5' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_3';
                                            }
                                            if (packageObj.package_name == 'PREMIUM_10' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_10';
                                            }
                                            if (packageObj.package_name == 'PREMIUM_20' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_20';
                                            }
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name + "-" + idx}>

                                                <input type="radio"

                                                    id={"change-package-" + packageObj.package_name}
                                                    name="selectedPackage"
                                                    value={packageObj.package_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.package_name == packageObj.package_name}
                                                />

                                                <label htmlFor={"change-package-" + packageObj.package_name}>{displayName}</label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-change-store-package"><i className="ladi-icon icon-e-remove" data-dismiss="modal-change-store-package" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        changePackageStore: (store) => dispatch(storeActions.changePackageStore(store)),
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ChangePackageStore);